import React from "react";
import { Link } from "gatsby";

import NQF from "../assets/NQF_Rating_Logo.png";
import QLD from "../assets/QLD_Kindy_Approved_Logo.png";
import SSA from "../assets/Sun_Smart_Approved_Logo.png";

const Footer = ({ siteTitle, facebook, email, address, phone }) => (
  <footer className="container mx-auto bg-secondary py-12 px-4 sm:px-8 md:px-8 text-center sm:text-left">
    <div className="flex flex-wrap justify-space-between sm:justify-between">
      <div className="w-full sm:w-auto flex flex-wrap justify-center md:justify-between order-1">
        <div className="flex flex-wrap w-full sm:w-auto mr-0 xs:mr-8 sm:mr-0">
          <div className="w-full sm:w-auto mr-0 sm:mr-6 mb-4 xs:mb-6">
            <a href={facebook} target="_blank" rel="noopener noreferrer" aria-label="Facebook">
              <svg
                width="36"
                height="36"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="m-auto sm:m-0"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0 18C0 8.05887 8.05887 0 18 0C27.9411 0 36 8.05887 36 18C36 27.9411 27.9411 36 18 36C8.05887 36 0 27.9411 0 18ZM19.8762 28.5836V18.7907H22.5794L22.9377 15.416H19.8762L19.8808 13.7269C19.8808 12.8467 19.9644 12.3751 21.2286 12.3751H22.9186V9H20.2149C16.9674 9 15.8243 10.6371 15.8243 13.3902V15.4163H13.8V18.791H15.8243V28.5836H19.8762Z"
                  fill="#1A5DC8"
                />
              </svg>
            </a>
          </div>
          <div className="w-full sm:w-auto mb-4 xs:mb-6">
            <h3 className="text-base text-primary font-semibold mb-1">{siteTitle}</h3>
            <p className="text-base text-primary address max-w-180 mx-auto sm:mx-0 my-0">
              {address}
            </p>
            <div className="hidden md:block mt-4 mb-4">
              <Link to="/terms" className="text-body opacity-50 text-sm mr-4">
                Terms
              </Link>
              <Link to="/privacy" className="text-body opacity-50 text-sm">
                Privacy
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full sm:w-auto order-3 sm:order-2 mt-0 md:mt-0 mb-0 sm:mb-4 md:mb-4">
        <div className="flex justify-center md:justify-between">
          <a
            href="https://www.qld.gov.au/education/earlychildhood/programs/about"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="NQF Rating"
          >
            <img
              className="mr-3"
              style={{ width: "80px", height: "auto" }}
              src={NQF}
              alt="NQF Rating Logo"
            />
          </a>
          <a
            href="https://www.acecqa.gov.au/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="ACECQA"
          >
            <img
              className="mr-3"
              style={{ width: "80px", height: "auto" }}
              src={QLD}
              alt="QLD Kindy Approved Logo"
            />
          </a>
          <a
            href="https://www.sunsmart.com.au/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Sun Smart"
          >
            <img
              className="mr-3"
              style={{ width: "80px", height: "auto" }}
              src={SSA}
              alt="Sun Smart Approved Logo"
            />
          </a>
        </div>
      </div>
      <div className="w-full sm:w-full md:w-auto flex flex-wrap order-2 sm:order-3 mt-0 sm:mt-4 md:mt-0">
        <div className="w-full sm:w-auto mb-4 xs:mb-0 mr-0 sm:mr-8">
          <h3 className="text-base text-primary font-semibold mb-1">Opening Hours</h3>
          <p className="text-base text-primary m-0">
            Monday – Friday
            <br />
            7am – 6pm
          </p>
          <div className="hidden md:block mt-4">
            <p className="text-body opacity-50 text-sm mr-4">
              {new Date().getFullYear()} © Copyright 2020.
            </p>
          </div>
        </div>
        <div className="w-full sm:w-auto mb-8 xs:mb-0">
          <h3 className="text-base text-primary font-semibold mb-1">Contact Us</h3>
          <ul className="mr-6 list-none p-0 m-0">
            <li className="m-0 p-0">
              <a
                className="no-underline text-primary m-0"
                href={`mailto:${email}`}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Email"
              >
                {email}
              </a>
            </li>
            <li className="m-0 p-0">
              <a
                className="no-underline text-primary m-0"
                href={`tel:${phone.toString().replace(/ /g, "")}`}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Phone"
              >
                {phone}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div className="block md:hidden mt-6">
      <p className="text-body opacity-50 text-sm mt-2 mb-0">
        {new Date().getFullYear()} © Copyright 2020.
      </p>
      <div className="mt-2">
        <Link to="/terms" className="text-body opacity-50 text-sm mr-2">
          Terms
        </Link>
        <Link to="/privacy" className="text-body opacity-50 text-sm">
          Privacy
        </Link>
      </div>
    </div>
  </footer>
);

export default Footer;
