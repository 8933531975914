import React from "react";
import { Link } from "gatsby";
import { ArrowRight } from "phosphor-react";

const CTALink = (props) => {
  let link = props.route || props.link || "#";
  if (
    props.landingPageRoute &&
    props.landingPageRoute.slug &&
    props.landingPageRoute.slug.current
  ) {
    link = props.landingPageRoute.slug.current;
  }

  if (props.kind === "button") {
    return (
      <Link
        to={`/${link}`}
        className="inline-flex items-center text-base text-white font-semibold no-underline bg-primary px-5 rounded-full"
        style={{ height: "36px" }}
      >
        {props.title}
      </Link>
    );
  }

  if (props.kind === "icon") {
    return (
      <Link
        to={`/${link}`}
        className="inline-flex items-center text-base text-white font-semibold no-underline bg-primary pl-5 rounded-full"
      >
        {props.title}
        <div
          className="flex items-center justify-center bg-orange rounded-full ml-4"
          style={{ width: "36px", height: "36px" }}
        >
          <ArrowRight color="white" size="30" />
        </div>
      </Link>
    );
  }

  // External
  if (props.link) {
    return (
      <a
        href={props.link}
        target="_blank"
        rel="noopener noreferrer"
        className="text-base text-primary font-semibold no-underline"
      >
        {props.title}
      </a>
    );
  }

  return (
    <Link to={`/${link}`} className="mr-3 text-base text-primary font-semibold no-underline">
      {props.title}
    </Link>
  );
};

export default CTALink;
